import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string {
        return localStorage.getItem('access_token') ?? '';
    }

    authentication(val) {
        this._authenticated = val;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(loggedIn): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        // return this._httpClient.post('api/authenticate/login', credentials).pipe(
        //     switchMap((response: any) => {

                // Store the access token in the local storage
                // this.accessToken = response.access_token;

                // Set the authenticated flag to true
                if(loggedIn){
                    this._authenticated = true;
                }
              

                // Store the user on the user service
                // this._userService.user = response.user;

                // Return a new observable with the response
                return of(loggedIn);
            // })
        // );
    }

    // GetAPIAuthentication() {
    //     var data = "username=" + environment.api_username + "&password=" + environment.api_password + "&grant_type=password";
    //     var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    //     return this._httpClient.post(environment.mainapiUrl + '/token', data, { headers: reqHeader });
    //   }

    GetAPIAuthenticationMethod() {
        // // console.log('GetAPIAuthentication')

    }

    // RoMa_signIn(): Observable<any>
    // {
    //     var data = "username=" + environment.api_username + "&password=" + environment.api_password + "&grant_type=password";
    //     var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    //     // Throw error, if the user is already logged in
    //     if ( this._authenticated )
    //     {
    //         return throwError('User is already logged in.');
    //     }
    //     // console.log(environment.mainapiUrl + '/token');
    //     // console.log(data)
    //     return this._httpClient.post(environment.mainapiUrl + '/token', data, {headers: reqHeader}).pipe(
    //         switchMap((response: any) => {
    //             // console.log(response);
    //             // Store the access token in the local storage
    //             this.accessToken = response.access_token;

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Store the user on the user service
    //             this._userService.user = response.user;

    //             // Return a new observable with the response
    //             return of(response);
    //         })
    //     );
    // }


    /**
     * Sign in using the access token
     */
    // signInUsingToken(): Observable<any> {
    //     // Renew token
    //     // // console.log('refresh')
    //     var data = "refresh_token=" + localStorage.get('Refresh_token_ID') + "&grant_type=refresh_token";
    //     var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    //     return this._httpClient.post(environment.mainapiUrl + '/token', data, { headers: reqHeader }).pipe(
    //         catchError(() => {
    //             // Return false
    //             return of(false);
    //         }),
    //         switchMap((access: any) => {
    //             localStorage.setItem('Access_token', access.access_token);
    //             localStorage.setItem('Refresh_token_ID', access.refresh_token);
    //             localStorage.setItem('Token_type', access.token_type);
    //             localStorage.setItem('Expiry_time', String(new Date().setSeconds(new Date().getSeconds() + Number(access.expires_in))));
    //             // Set the authenticated flag to true
    //             this._authenticated = true;
    //             // Return true
    //             return of(true);
    //         })
    //     );

    //     // return this._httpClient.post('api/auth/refresh-access-token', {
    //     //     access_token: this.accessToken
    //     // }).pipe(
    //     //     catchError(() => {

    //     //         // Return false
    //     //         return of(false);
    //     //     }),
    //     //     switchMap((response: any) => {

    //     //         // Store the access token in the local storage
    //     //         this.accessToken = response.access_token;

    //     //         // Set the authenticated flag to true
    //     //         this._authenticated = true;

    //     //         // Store the user on the user service
    //     //         this._userService.user = response.user;

    //     //         // Return true
    //     //         return of(true);
    //     //     })
    //     // );
    // }

    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('UserID');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string, email: string, password: string, company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string, password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        
        if (localStorage.getItem("isTabLoad")) {
            const tabLoadallowed = localStorage.getItem('isTabLoad');
            if (tabLoadallowed == '1') {
                localStorage.removeItem("isTabLoad");
                return of(true);
            }
        }
        // Check if the user is logged in
        if (this._authenticated) {
            // // console.log('test1')
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            // // console.log('test2')
            return of(false);
        }

        // Check the access token expire date
        if (this.tokenExpired()) {
            // // console.log('test3')
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    tokenExpired(): boolean {
        if (localStorage.getItem('Expiry_time')) {
            // // console.log(localStorage.getItem('Expiry_time'), new Date().setSeconds(new Date().getSeconds()), new Date().getTime())
            if (Number(localStorage.getItem('Expiry_time')) > new Date().getTime()) {
                // this._authenticated=false;
                return false;
            }
            else {
                return true;
            }
        }
    }

}
