<style>
    /* mat-dialog-content {
        width: 500px;
    } */

    /* mat-dialog-actions {
        float: right;
    } */
</style>

<h2 mat-dialog-title>SOFTWARE LICENSE</h2>
<mat-dialog-content>
    <h5>
        <p><b class="underline font-bold">1. Grant of Software License.</b> Roadside Systems, Inc. ('Licensor') hereby
            grants to the purchaser of the License
            Key and its End Users(collectively the 'Licensee') a nonexclusive, nontransferable license (the
            'License'&euro;),
            without the right to sublicense, to use the RoMa Server Software and its Improvements (collectively the
            'Licensed Software'&euro;) linked to each applicable License Key only: (i) in the quantity authorized by a
            License
            Key and (ii) for the term of the License purchased. Licensee shall use the Licensed Software only on the Key
            Server except as specifically permitted otherwise herein or by the applicable License Key. Licensee is
            prohibited from making any archival, backup, or other copies of the Licensed Software. Licensee is further
            prohibited from using the Licensed Software in any manner other than as described herein. Capitalized terms
            used
            herein but not otherwise defined shall have the respective meanings ascribed to them in the Terms of Use,
            located at www.roadsidesystems.net, which, along with the provisions set forth therein, are incorporated
            into
            this Agreement by this reference.</p>
    </h5>

    <h5>
        <p><b class="underline font-bold">2. Documentation License.</b> Licensor hereby grants Licensee a nonexclusive,
            nontransferable license, without the right to sublicense, to use the RoMa Program, RoMa Reports
            (collectively the '&euro;œDocumentation')generated in connection with Licensee'&euro;™s use of the Licensed Software
            and make copies of such Documentation for its internal business purposes to support Licensee'&euro;™s use of the
            Licensed Software, subject to the copyright requirement set forth herein. On each printed and reprinted copy
            of the Documentation (in any media), Licensee agrees to also reproduce and include Licensee'&euro;™s original
            copyright notice and any other notices that appear on the original copy of the Licensed Software and
            Documentation.</p>
    </h5>

    <h5>
        <p><b class="underline font-bold"> 3. Server Access.</b> Upon executing this Agreement and paying all fees
            required hereunder, Licensee will receivea user name and password ('&euro;œAccess Code'&euro;) to access the License
            Key and the applicable Key Server. Licensee represents and warrants that Licensee and its End Users shall be
            the sole users of the Access Code and shall maintain the confidentiality of the Access Code to prohibit
            unauthorized use by third parties. Licensee further represents that it will not assign, transfer or share
            its Access Codewith any third party that is not an End User of Licensee. Licensor shall provide the Access
            Code to the Key Server after Licensee signs this Agreement and payment of all fees have been made to
            Licensor, however, Licensee cannot and does not guarantee that activations will be processed within a
            specified timeframe.</p>


        <p>Licensee agrees that its use and access to the Key Server will at all times be in accordance with all
            applicable
            laws and regulations. The Access Code is provided to Licenseeas long as Licensee is paying its applicable
            fees
            to purchase the Licensed Software and as long as Licenseeisabusing or making fraudulent use of the Key
            Server,
            which abuse or fraud might be deemed to be any illegal or unlawful activity in Licensor'&euro;™s sole discretion;
            the
            collection, development or distribution of malicious code; hacking or cracking activities; the uploading of
            pornographic and/or copyright protected material and/or material that infringes any other person'&euro;™s rights.
        </p>

        <p>Excluding Maintenance Periods and downtime not caused by Licensor, the Key Server is intended to be
            electronically accessible and useable24-hours a day, 7-days a week. Licensor shall not be responsible or
            liable
            to Licensee if the Key Server is not accessible for any reason.Licensor reserves the right to restrict,
            suspend
            or terminate Licenseeâ&euro;™s or any of its End Userâ&euro;™s access to and/or use of all or any part of the Key
            Server
            at any time and for any reason in its sole discretion.</p>

        <p>Licensor will not be liable, and Licensee will not be entitled to a refund, for service outages or any lost
            information or data or Documentation, including, but not limited to service outages caused by or related to
            maintenance issues, downed servers, the technology that underlies the Licensed Software, Hardware, or Key
            Server, failures of Licensor'&euro;™s direct or indirect service (including, but not limited to,
            telecommunications,
            hosting and power), computer viruses, natural disasters or other destruction or damage to Licensorâ&euro;™s
            facilities, acts of nature, acts of God, war, civil disturbance, court order, legislative or regulatory
            action,
            catastrophic weather condition, third party interference or other causes beyond Licensorâ&euro;™s control.</p>
    </h5>

    <h5>
        <p><b class="underline font-bold">4. Compliance.</b> Licensor and/or its audit representative(s) may audit,
            examine and copy all Licensee records and/or systems relevant to a determination of Licensee'&euro;™s and its End
            User's&euro;™ compliance (or lack thereof) with the terms and conditions of this Agreement.Any such audits may be
            conducted on an annual basis (or as necessary based upon Licensorâ&euro;™s good faith belief that an additional
            audit is warranted) during Licenseeâ&euro;™s normal business hours upon not less than ten day's &euro;™prior written
            notice to Licensee. Information to which Licensor and/or its audit representatives become privy as a result
            of any such audit shall be treated as confidential information and shall be used only for purposes
            consistent with this Section. Licensor shall bear the expenses of any such audit unless the audit reveals a
            breach of this Agreement, in which case, Licensee shall reimburse Licensor for all reasonable costs and
            expenses (including relevant professional fees) associated with any such audit and the enforcement of this
            Agreement.</p>
    </h5>

    <h5>
        <p><b class="underline font-bold">5. LLicense Restrictions.</b> Licensee acknowledges that the scope of the
            License granted hereunder does not permit Licensee (and Licensee shall not allow any third party) to (or
            take any steps to): (i) decompile, disassemble, reverse engineer or attempt to reconstruct, identify or
            discover any source code, underlying ideas, design techniques, underlying user interface techniques or
            algorithms of the Licensed Software by any means whatever, or disclose any of the foregoing; (ii)
            distribute, lease, lend, use for timesharing, service bureau, and/or application service provider purposes
            the Licensed Software; (iii) use the Licensed Software for the benefit of third parties or allow third
            parties to use the Licensed Software; (iv) modify, incorporate into or with other software, or create a
            derivative work of, all or any part of the Licensed Software; (v) use the Licensed Software to develop or
            enhance any product that competes with the Licensed Software.</p>
    </h5>

    <h5>
        <p><b class="underline font-bold">6. Delivery.</b> Upon the acceptance of an order by Licensor and the
            satisfaction of all Licensor prerequisites to receive access to and use of the Licensed Software, Licensor
            shall deliver to and install for Licensee, the Licensed Software and/or Documentation, as applicable.</p>
    </h5>

    <h5>
        <p><b class="underline font-bold">7. Software Warranty.</b> Licensor warrants for a period of six months after
            the delivery of the Licensed Software to Licensee that such Licensed Software, as delivered, will be free
            from defects in the media and Error. In the event of an Error, Licensee shall notify Licensor within five
            days after its discovery of such Error and provide Licensor with all available information in written or
            electronic form so that Licensor can reproduce the Error. Licensorâ&euro;™s sole obligation is to undertake
            commercially reasonable efforts to correct the Error reported to Licensorduring the warranty period.
            LICENSORâ&euro;™S SOLE LIABILITY AND LICENSEEâ&euro;™S EXCLUSIVE REMEDY WITH RESPECT TO BREACH OF THE FOREGOING
            LIMITED WARRANTY WILL BE LIMITED TO ERROR CORRECTION OR PRODUCT REPLACEMENT, OR IF NEITHER IS IN
            LICENSOR'&euro;™S OPINION COMMERCIALLY FEASIBLE, REFUND OF THE LICENSE FEE RECEIVED BY LICENSOR FROM LICENSEE FOR
            THE LICENSED SOFTWARE THAT DOES NOT CONFORM WITH THE FOREGOING WARRANTY. LICENSOR DISCLAIMS ALL EXPRESS
            WARRANTIES OTHER THAN THOSE PROVIDED IN THIS AGREEMETN AND DISCLAIMS THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>
    </h5>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="flex items-center justify-between w-full border-t px-8 py-4">
        <h6>
            <mat-checkbox color="primary" [(ngModel)]="terms">I have read and agree to the Terms of Use</mat-checkbox>
        </h6>
        <div class="flex items-center">
            <button mat-raised-button mat-dialog-close tabindex="-1" class="mr-5">Reject</button>
            <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1"
                [disabled]="!terms">Accept</button>
        </div>
    </div>
</mat-dialog-actions>