import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AuthService } from '../auth.service';

@Injectable()
export class CanActivateChildGuard implements CanActivateChild {
  constructor(private readonly authService: AuthService, private _location: Location, private _authService: AuthService, private _router: Router,) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    window.addEventListener('hashchange', function () {
      if (!localStorage.getItem('preventEvent')) {
        sessionStorage.setItem('isManualEntered', '1');
      }
    });

    if (sessionStorage.getItem('isManualEntered')) {
      const isManualEntered = sessionStorage.getItem('isManualEntered');
      if (isManualEntered == '1') {
        sessionStorage.removeItem('isManualEntered');
        this.authService.signOut();
        this._location.go('/login');
        window.location.reload();
        return;
      }
    }
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

   /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
      // Check the authentication status
      return this._authService.check()
          .pipe(
              switchMap((authenticated) => {
                  const userID  =  localStorage.getItem('UserID');
                  // const accessToken = this._authService.accessToken;
                  // If the user is not authenticated...
                  if (!userID) {
                      // Redirect to the sign-in page
                      this._location.go('/login');
                      window.location.reload();
                      // this._router.navigate(['login'], {queryParams: {redirectURL}});

                      // Prevent the access
                      return of(false);
                  }

                  // Allow the access
                  return of(true);
              })
          );
  }
}