import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from 'environments/environment';
import {
    Router,
    // import as RouterEvent to avoid confusion with the DOM Event
    RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError,
} from '@angular/router'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    Navigation = false;
    constructor(private _authService: AuthService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _loadingBarService: LoadingBarService,
        private _router: Router,
        private _httpClient: HttpClient) {
        this._router.events
            .subscribe(
                (event: RouterEvent) => {
                    if (event instanceof NavigationStart) {
                        this.Navigation = true;
                        this._loadingBarService.useRef().start();
                    }
                    if (event instanceof NavigationEnd) {
                        this.Navigation = false;
                        this.LoadingBarStop();
                    }
                    if (event instanceof NavigationCancel) {
                        this.Navigation = false;
                        this.LoadingBarStop();
                    }
                    if (event instanceof NavigationError) {
                        this.Navigation = false;
                        this.LoadingBarStop();
                    }
                });
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */

    private refreshTokenInProgress = false;
    private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!this.Navigation) {
            this._loadingBarService.useRef().start();
        }
        // // console.log(request.url)
        // if (request.url.indexOf('token') !== -1) {
        // // console.log('line 3')
        return next.handle(request).pipe(tap((res) => {
            // this.LoadingBarStop();
            if (!this.Navigation) {
                this.LoadingBarStop();
            }
        }),
            catchError((error) => {
                if (!this.Navigation) {
                    this.LoadingBarStop();
                }
                // this.LoadingBarStop();
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
        // }

        const accessExpired = this._authService.tokenExpired();

        // if (accessExpired) {
        //     // // console.log('line 1');

        //     if (!this.refreshTokenInProgress) {
        //         // // console.log('line 1a');
        //         this.refreshTokenInProgress = true;
        //         this.refreshTokenSubject.next(null);
        //         var data = "refresh_token=" + localStorage.getItem('Refresh_token_ID') + "&grant_type=refresh_token";
        //         var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
        //         return this._httpClient.post(environment.mainapiUrl + '/token', data, { headers: reqHeader }).pipe(
        //             switchMap((access: any) => {
        //                 // // console.log(access)
        //                 localStorage.setItem('Access_token', access.access_token);
        //                 localStorage.setItem('Refresh_token_ID', access.refresh_token);
        //                 localStorage.setItem('Token_type', access.token_type);
        //                 localStorage.setItem('Expiry_time', String(new Date().setSeconds(new Date().getSeconds() + Number(access.expires_in))));
        //                 // this.authService.saveToken(AuthService.TOKEN_NAME, authResponse.accessToken);
        //                 // this.authService.saveToken(AuthService.REFRESH_TOKEN_NAME, authResponse.refreshToken);
        //                 this.refreshTokenInProgress = false;
        //                 this.refreshTokenSubject.next(access.refresh_token);
        //                 return next.handle(this.injectToken(request)).pipe(tap((res) => {
        //                     this.LoadingBarStop();
        //                 }),
        //                     catchError((error) => {
        //                         this.LoadingBarStop();
        //                         // Catch "401 Unauthorized" responses
        //                         if (error instanceof HttpErrorResponse && error.status === 401) {
        //                             // Sign out
        //                             this._authService.signOut();

        //                             // Reload the app
        //                             location.reload();
        //                         }

        //                         return throwError(error);
        //                     })
        //                 );
        //             }),
        //         );
        //     } else {
        //         // // console.log('line 1b');
        //         return this.refreshTokenSubject.pipe(
        //             filter(result => result !== null),
        //             take(1),
        //             switchMap((res) => {
        //                 return next.handle(this.injectToken(request))
        //             })
        //         );
        //     }
        // }

        // if (!accessExpired) {
        //     // // console.log('line 2');
        //     return next.handle(this.injectToken(request)).pipe(tap((res) => {
        //         this.LoadingBarStop();
        //     }),
        //         catchError((error) => {
        //             this.LoadingBarStop();
        //             // Catch "401 Unauthorized" responses
        //             if (error instanceof HttpErrorResponse && error.status === 401) {
        //                 // Sign out
        //                 this._authService.signOut();

        //                 // Reload the app
        //                 location.reload();
        //             }

        //             return throwError(error);
        //         })
        //     );
        // }
    }

    injectToken(request: HttpRequest<any>) {

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ` + localStorage.getItem('Access_token')
            }
        });
    }

    LoadingBarStop() {
        setTimeout(() => {
            this._loadingBarService.useRef().stop();
        }, 1000);
    }
}
