import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseHorizontalNavigationComponent } from '@fuse/components/navigation/horizontal/horizontal.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { AlertService } from 'app/shared/alert.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Component({
    selector: 'fuse-horizontal-navigation-basic-item',
    templateUrl: './basic.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseHorizontalNavigationBasicItemComponent implements OnInit, OnDestroy {
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    private _fuseHorizontalNavigationComponent: FuseHorizontalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _alertService: AlertService,
        private _httpClient: HttpClient,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the parent navigation component
        this._fuseHorizontalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Subscribe to onRefreshed on the navigation component
        this._fuseHorizontalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    Navigate(link) {
        if (link=="/usermedia")
        {
            localStorage.setItem('UserMedia', "Y");
        }
        else
        {
            localStorage.setItem('UserMedia', "");
        }
        localStorage.setItem('CurrentURL', link);
        if (link == '/sign-out') {
            // if (localStorage.getItem('MainUserID')) {
            //     localStorage.setItem('UserID', localStorage.getItem('MainUserID'));
            //     localStorage.removeItem('MainUserID');
            //     // this._router.navigate(['/switchrole']).then(() => {
            //     // });
            //     this._location.go('/switchrole');
            //     window.location.reload();
            // }
            // else {

            this._httpClient.post(environment.apiUrl, { MethodName: 'SaveUserActivity', InputStr: [{ "UserID": localStorage.getItem('UserID'), "Device": 'D', "Process": "Logout" }] }).subscribe((response) => {
                // console.log(response)
                let jsonStr1 = '';
                if (Object.keys(response).length > 0) {
                    for (let i = 0; i < response["Table"].length; i++) {
                        jsonStr1 += response["Table"][i][environment.SQLJsonString];
                    }
                    let jsonobj1 = JSON.parse(jsonStr1);
                    if (jsonobj1.Result[0]["ErrorID"] > 0) {
                        localStorage.removeItem('UserID');
                        localStorage.removeItem('CompanyID');
                        localStorage.removeItem('Access_token');
                        localStorage.removeItem('Refresh_token_ID');
                        localStorage.removeItem('Expiry_time');
                        this._router.navigate(['/sign-out']);
                    }
                    else {
                        this._alertService.show(jsonobj1.Result[0]["ErrorMessage"])
                    }
                }
                else {
                    this._alertService.show('Oops! Something Went Wrong')
                }
            })

            // }
        }
    }
}
