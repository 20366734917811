<style>
    mat-dialog-content {
        width: 500px;
    }

    mat-dialog-actions {
        float: right;
    }
</style>

<mat-dialog-content>
    <h4>Heads up!</h4>
    <p>
        {{message}}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close tabindex="-1" class="mr-5">{{cancelButtonText}}</button>
    <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
</mat-dialog-actions>