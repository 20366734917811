import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppService } from 'app/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CustomComponent implements OnInit, OnDestroy{
  isScreenSmall: boolean;
  MainUserID;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Output() Scheme = new EventEmitter<string>();
    scheme = 'light';
  constructor(_appService:AppService, private _fuseMediaWatcherService: FuseMediaWatcherService) { }

  ngOnInit(): void {
    this.MainUserID = localStorage.getItem("MainUserID");
    this._fuseMediaWatcherService.onMediaChange$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(({ matchingAliases }) => {
        this.isScreenSmall = !matchingAliases.includes('md');
    });
  }


  get currentYear(): number {
    return new Date().getFullYear();
}
toggleFullScreen() {
  if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
  } else {
      if (document.exitFullscreen) {
          document.exitFullscreen();
      }
  }
}

schemeChange(mode) {
  this.scheme = mode;
  this.Scheme.emit(mode);
}
onNavigate() {
  window.location.href = "../#/helpcenter";
}

ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.next();
  this._unsubscribeAll.complete();
}
}
