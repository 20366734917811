/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'Dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/dashboard'
    },
    {
        id: 'Job Complete',
        title: 'Job Complete',
        type: 'basic',
        icon: 'heroicons_outline:thumb-up',
        link: '/jobcomplete'
    },
    {
        id: 'client',
        title: 'Client',
        type: 'basic',
        icon: 'heroicons_outline:user-add',
        link: '/client'
    },
    {
        id: 'Location',
        title: 'Location',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/location'
    },
    {
        id: 'Asset',
        title: 'Asset',
        type: 'basic',
        icon: 'heroicons_outline:view-grid-add',
        link: '/asset'
    },
    {
        id: 'AssetGroup',
        title: 'Asset Group',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/assetgroup'
    },
    {
        id: 'ClientLocation',
        title: 'Nested Table',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/client-location'
    },
    {
        id: 'grid-right-edit',
        title: 'Grid with Right Side Edit',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/grid-with-right-edit'
    },
    {
        id: 'inventory',
        title: 'Inventory',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/inventory'
    },
    {
        id: 'Job Type',
        title: 'Job Type',
        type: 'basic',
        icon: 'heroicons_outline:document-add',
        link: '/jobtype'
    },
    {
        id: 'Job Task',
        title: 'Job Task',
        type: 'basic',
        icon: 'heroicons_outline:check',
        link: '/jobtask'
    },
    {
        id: 'Setting',
        title: 'Setting',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/setting'
    },
    {
        id: 'example',
        title: 'example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },
    {
        id: 'base',
        title: 'Base Crud Demo',
        type: 'basic',
        icon: 'heroicons_outline:calculator',
        link: '/base'
    },
    {
        id: 'changepassword',
        title: 'Change Password',
        type: 'basic',
        icon: 'heroicons_outline:calculator',
        link: '/changepassword'
    },
    {
        id: 'news',
        title: 'News & Info',
        type: 'basic',
        icon: 'heroicons_outline:calculator',
        link: '/news'
    },
    {
        id: 'editprofile',
        title: 'Edit Profile',
        type: 'basic',
        icon: 'heroicons_outline:calculator',
        link: '/editprofile'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },
    {
        id: 'demo-page',
        title: 'Crud Demo',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/demo-page'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },
    {
        id: 'demo-page',
        title: 'Crud Demo',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/demo-page'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    },
    {
        id: 'demo-page',
        title: 'Crud Demo',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/base'
    }
];
