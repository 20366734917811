import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatSnackBarConfig
} from '@angular/material/snack-bar';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from './dialog/dialog.component';
import { TermsConfirmationDialog } from './terms-dialog/terms-dialog.component';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private status = new BehaviorSubject(null);
    config: MatSnackBarConfig = { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top', panelClass: [] }
    constructor(private _snackBar: MatSnackBar,
        private dialog: MatDialog) {
    }
    show(message) {
        if (message != 'No Record Found') {
            this._snackBar.open(message, '', this.config);
        }
    }
    confirmationDialog(message: string = "Are you sure want to delete?", ok: string = "Yes", cancel: string = "No"): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: "Are you sure want to delete ?",
                buttonText: {
                    ok: "OK",
                    cancel: "Cancel"
                }
            }
        });
        return dialogRef.afterClosed();
    }

    ResetconfirmationDialog(message: string = "Are you sure want to Reset this User Password?", ok: string = "Yes", cancel: string = "No"): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: "Are you sure want to Reset Password ?",
                buttonText: {
                    ok: "Yes",
                    cancel: "No"
                }
            }
        });

        return dialogRef.afterClosed();
    }

    ChangeStatusconfirmationDialog(message: string = "Are you sure want to Change Status?", ok: string = "Yes", cancel: string = "No"): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: message,
                buttonText: {
                    ok: "Yes",
                    cancel: "No"
                }
            }
        });

        return dialogRef.afterClosed();
    }

    DeleteconfirmationDialog(message: string = "Are you sure want to delete?", ok: string = "Yes", cancel: string = "No"): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: message,
                buttonText: {
                    ok: "Yes",
                    cancel: "No"
                }
            }
        });
        return dialogRef.afterClosed();
    }

    termsConfirmationDialog() {
        const dialogRef = this.dialog.open(TermsConfirmationDialog, {});
        return dialogRef.afterClosed();
    }


}