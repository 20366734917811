<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-8" src="assets/images/logo/logo.png" alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <div class="flex items-center ml-2 mr-4 ">
                <!-- Light version -->
                <img class="w-8 dark:hidden" src="assets/images/logo/logo.png" alt="Logo image">
                <!-- Dark version -->
                <img class="hidden dark:flex w-8" src="assets/images/logo/logo.png" alt="Logo image">
            </div>
            <fuse-horizontal-navigation class="mr-2 ml-8" [name]="'mainNavigation'" [navigation]="navigation">
            </fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->

        <div class="flex items-center pl-2 ml-auto space-x-2">
            <div class="flex flex-col w-52 mt-1">
                <mat-select [(ngModel)]="DepartmentID" [placeholder]="'Department'"
                    (ngModelChange)="DepartmentOnChange()">
                    <!-- <mat-option value=-1 *ngIf="Department.length>0">
                        All</mat-option> -->
                    <mat-option [value]="option.DepartmentID" *ngFor="let option of Department;let i=index;">
                        {{option.Department}}</mat-option>
                </mat-select>
            </div>
            <!-- <search [appearance]="'bar'"></search>
            <shortcuts [shortcuts]="data.shortcuts"></shortcuts>
            <messages [messages]="data.messages"></messages>
            <notifications [notifications]="data.notifications"></notifications>
            <user-menu></user-menu> -->
            <button mat-icon-button (click)="Navigateswitchrole()" *ngIf="MainUserID>0">
                <mat-icon svgIcon="heroicons_outline:home"></mat-icon>
            </button>
            <button mat-icon-button (click)="toggleFullScreen()">
                <mat-icon svgIcon="heroicons_outline:arrows-expand"></mat-icon>
            </button>
            <!-- <messages [messages]="data.messages" *ngIf="CompanyID > 1"></messages> -->
            <button mat-icon-button (click)="onNavigate()">
                <mat-icon [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
            </button>
            <button *ngIf="scheme!='light'" mat-icon-button (click)="schemeChange('light')">
                <mat-icon svgIcon="heroicons_outline:sun"></mat-icon>
            </button>
            <button mat-mini-fab *ngIf="scheme=='light'" (click)="schemeChange('light')" [color]="'primary'">
                <mat-icon svgIcon="heroicons_outline:sun"></mat-icon>
            </button>
            <button *ngIf="scheme!='dark'" mat-icon-button (click)="schemeChange('dark')">
                <mat-icon svgIcon="heroicons_outline:moon"></mat-icon>
            </button>
            <button mat-mini-fab *ngIf="scheme=='dark'" (click)="schemeChange('dark')" [color]="'primary'">
                <mat-icon svgIcon="heroicons_outline:moon"></mat-icon>
            </button>
            <user-menu></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">RockLake &copy; {{currentYear}}</span>
    </div>

</div>