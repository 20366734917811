import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule, NoPreloading } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { GlobalErrorHandler } from './basicerror.handler';
import { CanActivateChildGuard } from './core/auth/guards/can-activate-child.gaurd';


const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',

    preloadingStrategy: NoPreloading,
    relativeLinkResolution: 'legacy',
    useHash: true,
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse & Fuse Mock API
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),
        LoadingBarModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [CanActivateChildGuard,
        {provide: ErrorHandler, useClass: GlobalErrorHandler}
      ]
    
})
export class AppModule {
}
