import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

if ( environment.production )
{
    window.console.log = () => { }

    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    // Ensure Angular destroys itself on hot reloads.


    if (window['ngRef']) {
        window['ngRef'].destroy();
    }
    window['ngRef'] = ref;
})
    .catch(err => console.error(err));