import * as moment from 'moment';
import { Message } from 'app/layout/common/messages/messages.types';

/* tslint:disable:max-line-length */
export const messages: Message[] = [{
		id: '832276cc-c5e9-4fcc-8e23-d38e2e267bc9',
		image: 'assets/images/avatars/male-01.jpg',
		title: 'Gary Peters',
		description: 'Completed Monthly Inspection on Truck-123',
		time: moment().subtract(25, 'minutes').toISOString(), // 25 minutes ago
		read: false
	},
	{
		id: '608b4479-a3ac-4e26-8675-3609c52aca58',
		image: 'assets/images/avatars/male-04.jpg',
		title: 'Leo Gill',
		description: 'Completed Monthly Inspection on Van43',
		time: moment().subtract(50, 'minutes').toISOString(), // 50 minutes ago
		read: false
	},
	{
		id: '22148c0c-d788-4d49-9467-447677d11b76',
		image: 'assets/images/avatars/female-01.jpg',
		title: 'Sarah',
		description: 'Completed Weekly Inspection on Truck-456',
		time: moment().subtract(3, 'hours').toISOString(), // 3 hours ago
		read: false
	},
	{
		id: '492e2917-760c-4921-aa5a-3201a857cd48',
		image: 'assets/images/avatars/female-12.jpg',
		title: 'Nancy Salazar ',
		description: 'Completed Daily Inspection on Toyota-387',
		time: moment().subtract(5, 'hours').toISOString(), // 5 hours ago
		read: false
	}
];
