import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AlertService } from './shared/alert.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private _alert: AlertService) {
    
  }

  handleError(error: Error) {
      this._alert.show(error.message);
      console.log(error.stack)
  }
}