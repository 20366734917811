import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AlertService } from './shared/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  UserID;
  Device;
  constructor(private _httpClient: HttpClient,
    private _alert: AlertService,) {

  }
  private UserInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  private MenuList: BehaviorSubject<any> = new BehaviorSubject(null);
  private CountInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  private NotificationInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  private NotificationCount: BehaviorSubject<any> = new BehaviorSubject(null);
  private DepartmentList: BehaviorSubject<any> = new BehaviorSubject(null);
  private AgeList: BehaviorSubject<any> = new BehaviorSubject(null);
  private GenderList: BehaviorSubject<any> = new BehaviorSubject(null);
  private OriginalWorkList: BehaviorSubject<any> = new BehaviorSubject(null);
  private QuestionTypeList: BehaviorSubject<any> = new BehaviorSubject(null);
  private ModuleList: BehaviorSubject<any> = new BehaviorSubject(null);



  get UserInfo$(): Observable<any> {
    return this.UserInfo.asObservable();
  }
  get MenuList$(): Observable<any> {
    return this.MenuList.asObservable();
  }
  get CountInfo$(): Observable<any> {
    return this.CountInfo.asObservable();
  }

  get NotificationCount$(): Observable<any> {
    return this.NotificationCount.asObservable();
  }
  get NotificationInfo$(): Observable<any> {
    return this.NotificationInfo.asObservable();
  }

  get DepartmentList$(): Observable<any> {
    return this.DepartmentList.asObservable();
  }

  get AgeList$(): Observable<any> {
    return this.AgeList.asObservable();
  }

  get GenderList$(): Observable<any> {
    return this.GenderList.asObservable();
  }
  

  get OriginalWorkList$(): Observable<any> {
    return this.OriginalWorkList.asObservable();
  }
  get QuestionTypeList$(): Observable<any> {
    return this.QuestionTypeList.asObservable();
  }

  get ModuleList$(): Observable<any> {
    return this.ModuleList.asObservable();
  }


  getPreloadContent(normalconent=false): Observable<any> {
    const obj = { "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device') }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetPreLoadData', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data)
        // console.log(Object.keys(data))
        let Tablestring = [];
        let Tablestring2 = [];
        let TableParse = []

        if (Object.keys(data).length > 0) {
          let count = Object.keys(data).length
          for (let i = 0; i < count; i++) {
            let jsonStr1 = "";

            // jsonStr1 += data["Table"][i][environment.SQLJsonString];
            Tablestring[i] = data[(Object.keys(data)[i])];
            for (let j = 0; j < (data[(Object.keys(data)[i])]).length; j++) {
              jsonStr1 += data[(Object.keys(data)[i])][j][environment.SQLJsonString];
            }

            Tablestring2[i] = jsonStr1
            TableParse[i]=JSON.parse(Tablestring2[i])

            // console.log(TableParse[i].Result)
            switch (Object.keys(TableParse[i])[0]) {
              case "Age":
                {
                  if (Object.keys(TableParse[i-1])[0] == "Result") {
                    if (TableParse[i-1].Result[0]["ErrorID"] > 0) 
                    {
                      // console.log(TableParse[i].Age)
                      this.AgeList.next(TableParse[i].Age)

                    }
                  }
                  break;
                }
              case "Gender":
                {
                  if (Object.keys(TableParse[i-1])[0] == "Result") {
                    // if (TableParse[i-1].Result[0]["ErrorID"] > 0) 
                    {
                      // console.log(TableParse[i].Gender)
                      this.GenderList.next(TableParse[i].Gender)
                    }
                  }
                  break;
                }
              case "OriginalWork":
                {
                  if (Object.keys(TableParse[i-1])[0] == "Result") {
                    if (TableParse[i-1].Result[0]["ErrorID"] > 0) 
                    {
                      // console.log(TableParse[i].OriginalWork)
                      this.OriginalWorkList.next(TableParse[i].OriginalWork)

                    }
                  }
                  break;
                }
              case "QuestionType":
                {
                  if (Object.keys(TableParse[i-1])[0] == "Result") {
                    if (TableParse[i-1].Result[0]["ErrorID"] > 0) 
                    {
                      // console.log(TableParse[i].QuestionType)
                      this.QuestionTypeList.next(TableParse[i].QuestionType)

                    }
                  }
                  break;
                }
              case "Module":
                {
                  if (Object.keys(TableParse[i-1])[0] == "Result") {
                    if (TableParse[i-1].Result[0]["ErrorID"] > 0) 
                    {
                      // console.log(TableParse[i].Module)
                      this.ModuleList.next(TableParse[i].Module)

                    }
                  }
                  break;
                }
                // case "Result":
                // {
                //   if(Object.keys(TableParse[i])[0] == "Result" && normalconent){
                //     // if (TableParse[i].Result[0]["ErrorID"] > 0) 
                //     {
                //       console.log(TableParse[i].Result)
                //     }

                //   }
                // }

                // default :
                // {
                //   if (Object.keys(TableParse[i])[0] == "Result"){
                //     if (TableParse[i].Result[0]["ErrorID"] > 0) 
                //     {
                //       console.log(TableParse[i].Result)
                //     }
                //   }
                // }

            }

          }
          // for (let i = 0; i < Tablestring2.length; i++) {

          // }
          // console.log(Tablestring)
          // console.log(Tablestring2)

        }
      })
    )
  }
  getUserAssets():
    Observable<any> {
    this.UserID = localStorage.getItem('UserID');
    this.Device = localStorage.getItem('Device');
    const obj = { "UserID": this.UserID, "Device": this.Device }
    // console.log(obj)
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetUserLogin', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data)
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            // // console.log(JSON.parse(jsonStr2).Result)
            localStorage.setItem('DepartmentID', JSON.parse(jsonStr2).Result[0].DepartmentID);
            this.UserInfo.next(JSON.parse(jsonStr2).Result);
            let jsonStr3: any = "";
            for (let i = 0; i < data["Table2"].length; i++) {
              jsonStr3 += data["Table2"][i][environment.SQLJsonString];
            }

            let menu = JSON.parse(jsonStr3).Result;

            menu.forEach(element => {
              if (element.badge) {
                element.badge = JSON.parse(element.badge)[0];
              }
              if (element.children?.length) {
                // // console.log(element.children)
                element.children.forEach(ele => {
                  if (ele.badge) {
                    ele.badge = JSON.parse(ele.badge)[0];
                  }
                  if (ele.children) {
                    ele.children = JSON.parse(ele.children);
                    // // console.log(ele.children)
                    ele.children.forEach(el => {
                      if (el.badge) {
                        el.badge = JSON.parse(el.badge)[0];
                      }
                    });
                  }
                });
              }
            });
            // // console.log('Final', menu)
            this.MenuList.next(menu);
            // let jsonStr4: any = "";
            // for (let i = 0; i < data["Table3"].length; i++) {
            //   jsonStr4 += data["Table3"][i][environment.SQLJsonString];
            // }
            // // // console.log(JSON.parse(jsonStr4).Result)
            // this.CountInfo.next(JSON.parse(jsonStr4).Result);

            // let jsonStr5: any = "";
            // for (let i = 0; i < data["Table5"].length; i++) {
            //   jsonStr5 += data["Table5"][i]["NoofNotification"];
            //   //this.NotificationCount= data["Table5"][i]["NoofNotification"];
            //   // this.NotificationInfo.next(JSON.parse( data["Table5"][i]["NoofNotification"]).Result);
            // }
            // //  // console.log(JSON.parse(jsonStr5))
            // this.NotificationCount.next(JSON.parse(jsonStr5));

            // let jsonStr6: any = "";
            // for (let i = 0; i < data["Table6"].length; i++) {
            //   jsonStr6 += data["Table6"][i][environment.SQLJsonString];
            // }
            // // // console.log(JSON.parse(jsonStr2).Result)
            // if(data["Table6"].length){
            //   this.NotificationInfo.next(JSON.parse(jsonStr6).Result);
            // }else{
            //   this.NotificationInfo.next([]);
            // }


          }
          else {
            this.UserInfo.next([]);
            this.MenuList.next([]);
            // this.CountInfo.next([]);
            // this.NotificationCount.next([]);
            // this.NotificationInfo.next([]);
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.UserInfo.next([]);
          this.MenuList.next([]);
          // this.CountInfo.next([]);
          // this.NotificationCount.next([]);
          // this.NotificationInfo.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }


  getDepartmentList():
    Observable<any> {
    const obj = { "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device'), "DepartmentID": -1 }
    // const obj = { "UserID": "4", "Device": "D" }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'GetDepartment', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data);
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {
            let jsonStr2: any = "";
            for (let i = 0; i < data["Table1"].length; i++) {
              jsonStr2 += data["Table1"][i][environment.SQLJsonString];
            }
            // console.log(localStorage.getItem('DepartmentID'))
            if (localStorage.getItem('DepartmentID')) {
              let index = JSON.parse(jsonStr2).Result.findIndex(f => f.DepartmentID == localStorage.getItem('DepartmentID'));
              // console.log(index)
              if (index != -1) {
                localStorage.setItem('DepartmentName', JSON.parse(jsonStr2).Result[index].Department)
              } else {
                localStorage.setItem('DepartmentID', JSON.parse(jsonStr2).Result[0].DepartmentID);
                localStorage.setItem('DepartmentName', JSON.parse(jsonStr2).Result[0].Department)
              }
            }
            this.DepartmentList.next(JSON.parse(jsonStr2).Result);
          }
          else {
            this.DepartmentList.next([]);
            //this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this.DepartmentList.next([]);
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }

  DepartmentChange(DepartmentID):
    Observable<any> {
    const obj = { "UserID": localStorage.getItem('UserID'), "Device": localStorage.getItem('Device'), "DepartmentID": DepartmentID }
    // const obj = { "UserID": "4", "Device": "D" }
    return this._httpClient.post<any>(environment.apiUrl, { MethodName: 'SaveUserDepartment', InputStr: [obj] }).pipe(
      tap((data) => {
        // console.log(data);
        let jsonStr1 = "";
        if (Object.keys(data).length > 0) {
          for (let i = 0; i < data["Table"].length; i++) {
            jsonStr1 += data["Table"][i][environment.SQLJsonString];
          }
          let jsonobj1 = JSON.parse(jsonStr1);
          if (jsonobj1.Result[0]["ErrorID"] > 0) {

          }
          else {
            this._alert.show(jsonobj1.Result[0]["ErrorMessage"])
          }
        }
        else {
          this._alert.show('Oops! Something Went Wrong')
        }
      })
    )

  }
}
