export const environment = {
    AuthEnable: true,
    production: true,
    apiUrl: 'https://api.rocklakemed.com/Api/Rocklake/InvokeProcedure',
    apiUrl_forgotPassword: 'https://api.rocklakemed.com/Api/Rocklake/ResetPassword',
    apiUrl_addfile: 'https://api.rocklakemed.com/Api/Rocklake/AddFileWithThumbnail',
    SQLJsonString: 'JSON_F52E2B61-18A1-11d1-B105-00805F49916B',
    IK_Imagekit_publicKey: "public_rMg1fznWaH373ckPkgJtNTf0UT4=",
    IK_Imagekit_urlEndpoint: "https://ik.imagekit.io/vfs6wqzjf",
    IK_Imagekit_authenticationEndpoint: "http://roma20api.feafu.in/api/Test/ImageKitAuthCheck",

    // Alpha
    // apiUrl: 'https://alphaapi.rocklakemed.com/Api/Rocklake/InvokeProcedure',
    // apiUrl_forgotPassword: 'https://alphaapi.rocklakemed.com/Api/Rocklake/ResetPassword',
    // apiUrl_addfile: 'https://alphaapi.rocklakemed.com/Api/Rocklake/AddFileWithThumbnail',
};

