import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService } from '@fuse/components/navigation';
import { InitialData } from 'app/app.types';
import { AppService } from 'app/app.service';
import { Location } from '@angular/common';
@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
    data: InitialData;
    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();


    //
    navigation: any = [];
    UserName = '';
    EMailID = '';
    Logo = null;
    CompanyID: number = 0;
    MainUserID;
    Department: any = [];
    DepartmentID = Number(localStorage.getItem('DepartmentID'));
    @Output() Scheme = new EventEmitter<string>();
    scheme = 'light';


    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _appService: AppService,
        private _location: Location
    ) {
        // this._activatedRoute.queryParams.subscribe(params => {
        //     // console.log(params); // Print the parameter to the console. 
        // });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.MainUserID = localStorage.getItem("MainUserID");
        // console.log(this.MainUserID);
        // this._loginService.NavigationBarList$.subscribe((response)=>{
        //    this.navigation=response;
        // })


        this._appService.MenuList$.subscribe((response) => {
            // console.log(response);

            this.navigation = response;
        });
        this._appService.UserInfo$.subscribe((response) => {
            // console.log(response)
            if (response.length) {
                this.UserName = response[0].FirstName + " " + response[0].LastName;
                this.EMailID = response[0].EMailID;
                this.Logo = response[0].DesktopLogoSrc;
                this.DepartmentID = response[0].DepartmentID;
                this.CompanyID = response[0]["CompanyID"];
                localStorage.setItem('ModuleName', response[0]["ModuleName"]);
                localStorage.setItem('CompanyID', response[0]["CompanyID"]);
                // localStorage.setItem('DepartmentID', response[0]["DepartmentID"]);
            }

        })

        this._appService.DepartmentList$.subscribe((DepartmentList) => {
            // console.log(DepartmentList);
            this.Department = DepartmentList;
            // let index = DepartmentList.findIndex(f => f.DepartmentID == this.DepartmentID);
            // if (index != -1) {
            //     localStorage.setItem('DepartmentName', DepartmentList[index].Department)
            // } else {
            this.DepartmentID = Number(localStorage.getItem('DepartmentID'));
            // localStorage.setItem('DepartmentID', DepartmentList[0].DepartmentID);
            //     localStorage.setItem('DepartmentName', DepartmentList[0].Department)
            // }
        });

        // Subscribe to the resolved route mock-api
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent(name);
        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleFullScreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }
    onNavigate() {
        //this.router.navigateByUrl("https://www.google.com");
        window.location.href = "../#/helpcenter";
    }
    schemeChange(mode) {
        this.scheme = mode;
        // console.log(mode)
        this.Scheme.emit(mode)
    }
    DepartmentOnChange(): void {
        // localStorage.setItem('DepartmentID', String(this.DepartmentID));.
        // // console.log(this._router.url, localStorage.getItem('CurrentURL'))
        // // console.log(this.DepartmentID);
        this._appService.DepartmentChange(this.DepartmentID).subscribe((res) => {
            localStorage.setItem('DepartmentID', String(this.DepartmentID));
            this._location.go(localStorage.getItem('CurrentURL'))
            window.location.reload();
        });
        // window.location.reload();
    }
}
